import { MenuItem } from '@blueprintjs/core';
import { ItemRenderer, MultiSelect2 } from '@blueprintjs/select';
import algoliasearch from 'algoliasearch/lite';
import { FieldArray, getIn } from 'formik';
import React, { useRef } from 'react';
import { AutocompleteProvided, Configure, Hit } from 'react-instantsearch-core';
import { connectAutoComplete, InstantSearch } from 'react-instantsearch-dom';
import config from '../helpers/config';
import { getSubdomain } from '../helpers/utils';

type MultiSelectSearchInputComponentProps<T> = {
  name: string;
  labelPath: string;
  renderItem: (hit: Hit<T>) => React.ReactNode;
};

const MultiSelectSearchInputComponent = <T extends { id: number }>({
  name,
  hits,
  refine,
  currentRefinement,
  labelPath,
  renderItem,
}: MultiSelectSearchInputComponentProps<T> & AutocompleteProvided<T>) => (
  <FieldArray name={name}>
    {({ form, push, remove }) => {
      const selectedItems: Hit<T>[] | undefined = getIn(form.values, name);

      const renderTag = (item: Hit<T>) => getIn(item, labelPath);

      const isItemSelected = (item: Hit<T>) => getSelectedItemIndex(item) !== -1;

      const getSelectedItemIndex = (item: Hit<T>) =>
        selectedItems?.findIndex((selectedItem) => selectedItem.id === item.id) ?? -1;

      // NOTE: not using Films.itemRenderer here so we can set icons.
      const renderItemItem: ItemRenderer<Hit<T>> = (item, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
          return null;
        }
        return (
          <MenuItem
            active={modifiers.active}
            icon={isItemSelected(item) ? 'tick' : 'blank'}
            key={item.id}
            onClick={handleClick}
            text={renderItem(item)}
            shouldDismissPopover={false}
          />
        );
      };

      const handleItemSelect = (item: Hit<T>) => {
        if (!isItemSelected(item)) {
          push(item);
        } else {
          remove(getSelectedItemIndex(item));
        }
      };

      const handleTagRemove = (_tag: React.ReactNode, index: number) => {
        remove(index);
      };

      const convertHits = (hits: any[]): Hit<T>[] =>
        hits.map((hit) => ({
          ...hit,
          id: hit.id.toString(),
        }));

      return (
        <MultiSelect2<Hit<T>>
          fill
          itemRenderer={renderItemItem}
          itemListPredicate={(_, items) => items} // Filtering done by Algolia obviously
          tagRenderer={renderTag}
          query={currentRefinement}
          onQueryChange={refine}
          resetOnSelect
          itemsEqual="id"
          items={convertHits(hits)}
          selectedItems={selectedItems ?? []}
          placeholder="Suchen..."
          onItemSelect={handleItemSelect}
          noResults={<MenuItem disabled={true} text="Keine Resultate." />}
          popoverProps={{ minimal: true, rootBoundary: 'viewport' }}
          tagInputProps={{
            onRemove: handleTagRemove,
            disabled: form.isSubmitting,
          }}
        />
      );
    }}
  </FieldArray>
);

type MultiSelectSearchInputProps<T> = {
  index: string;
  filters?: string;
} & MultiSelectSearchInputComponentProps<T>;

const MultiSelectSearchInput = <T extends { id: number }>({
  index,
  filters,
  ...componentProps
}: MultiSelectSearchInputProps<T>) => {
  const ConnectedMultiSelectSearchInputComponent = connectAutoComplete<
    MultiSelectSearchInputComponentProps<T> & AutocompleteProvided<T>,
    T
  >(MultiSelectSearchInputComponent);
  const searchClient = useRef(algoliasearch(config.algolia.appId, config.algolia.appSecret)).current;

  return (
    // @ts-expect-error Test
    <InstantSearch searchClient={searchClient} indexName={`${getSubdomain()}_${index}`}>
      {/* @ts-expect-error Test */}
      <ConnectedMultiSelectSearchInputComponent {...componentProps} />
      {/* @ts-expect-error Test */}
      <Configure hitsPerPage={12} filters={filters} />
    </InstantSearch>
  );
};

export default MultiSelectSearchInput;
