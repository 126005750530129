import { Button, Menu, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemListRenderer, ItemRenderer, Select2 } from '@blueprintjs/select';
import algoliasearch from 'algoliasearch/lite';
import { getIn } from 'formik';
import React, { useRef } from 'react';
import { AutocompleteProvided, Configure, Hit } from 'react-instantsearch-core';
import { connectAutoComplete, InstantSearch } from 'react-instantsearch-dom';
import config from '../helpers/config';
import { getSubdomain } from '../helpers/utils';

type DropdownSearchComponentProps<T> = {
  value: Hit<T> | null | undefined;
  onChange: (value: Hit<T> | null | undefined) => void;
  canClear?: boolean;
  labelPath: string;
  renderItem: (hit: Hit<T>) => React.ReactNode;
};

const DropdownSearchComponent = <T extends { id: number }>({
  canClear = true,
  renderItem,
  labelPath,
  value,
  onChange,
  hits,
  refine,
  currentRefinement,
}: DropdownSearchComponentProps<T> & AutocompleteProvided<T>) => {
  const itemListRenderer: ItemListRenderer<Hit<T>> = ({ items, itemsParentRef, renderItem }) => {
    const renderedItems = items.map(renderItem).filter((item) => item != null);
    return <Menu ulRef={itemsParentRef}>{renderedItems}</Menu>;
  };

  const itemRenderer: ItemRenderer<Hit<T>> = (item, { handleClick, modifiers }) =>
    !modifiers.matchesPredicate ? null : (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.id}
        onClick={handleClick}
        text={renderItem(item)}
      />
    );

  const getLabel = (item: Hit<T>) => getIn(item, labelPath);

  const onSelect = (item: Hit<T> | undefined | null) => {
    onChange(item);
  };

  return (
    <div className="flex">
      <Select2<Hit<T>>
        items={hits}
        itemListRenderer={itemListRenderer}
        itemListPredicate={(_, items) => items} // Filtering done by Algolia obviously
        itemRenderer={itemRenderer}
        query={currentRefinement}
        onQueryChange={refine}
        noResults={<MenuItem disabled={true} text="Keine Resultate." />}
        onItemSelect={onSelect}
        popoverProps={{
          captureDismiss: true,
          minimal: true,
        }}
      >
        {/* children become the popover target; render value here */}
        <Button text={value ? getLabel(value) : '—'} rightIcon={IconNames.DOUBLE_CARET_VERTICAL} />
      </Select2>
      {canClear && !!value && (
        <Button minimal icon={IconNames.DELETE} onClick={() => onSelect(null)} className="ml-2" />
      )}
    </div>
  );
};

type DropdownSearchProps<T> = {
  index: string;
  canClear?: boolean;
  filters?: string;
} & DropdownSearchComponentProps<T>;

const DropdownSearch = <T extends { id: number }>({ index, filters, ...componentProps }: DropdownSearchProps<T>) => {
  const ConnectedDropdownSearchComponent = connectAutoComplete<
    DropdownSearchComponentProps<T> & AutocompleteProvided<T>,
    T
  >(DropdownSearchComponent);
  const searchClient = useRef(algoliasearch(config.algolia.appId, config.algolia.appSecret)).current;

  return (
    // @ts-expect-error Test
    <InstantSearch searchClient={searchClient} indexName={`${getSubdomain()}_${index}`}>
      {/* @ts-expect-error Test */}
      <ConnectedDropdownSearchComponent {...componentProps} />
      {/* @ts-expect-error Test */}
      <Configure hitsPerPage={12} filters={filters} />
    </InstantSearch>
  );
};

export default DropdownSearch;
