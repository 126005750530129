import { DocumentNode, useMutation } from '@apollo/client';
import { Alert, Button, Elevation, HTMLSelect, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { ChangeEvent, useState } from 'react';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import Text from '../../../components/Text';
import { SortOrder, Trashed } from '../../../generated/graphql';
import { showErrorMessage } from '../../../helpers/graphql';
import { ItemClass, ListComponent } from '../../../types';
import CategoryList from '../../categories/components/CategoryList';
import {
  CATEGORY_LIST_QUERY,
  EMPTY_CATEGORY_TRASH_MUTATION,
  FORCE_DELETE_CATEGORIES_MUTATION,
  RESTORE_CATEGORIES_MUTATION,
} from '../../categories/queries';
import CompanionList from '../../companions/components/CompanionList';
import {
  COMPANION_LIST_QUERY,
  EMPTY_COMPANION_TRASH_MUTATION,
  FORCE_DELETE_COMPANIONS_MUTATION,
  RESTORE_COMPANIONS_MUTATION,
} from '../../companions/queries';
import ContactList from '../../contacts/components/ContactList';
import {
  CONTACT_LIST_QUERY,
  EMPTY_CONTACT_TRASH_MUTATION,
  FORCE_DELETE_CONTACTS_MUTATION,
  RESTORE_CONTACTS_MUTATION,
} from '../../contacts/queries';
import DonorList from '../../donors/components/DonorList';
import {
  DONOR_LIST_QUERY,
  EMPTY_DONOR_TRASH_MUTATION,
  FORCE_DELETE_DONORS_MUTATION,
  RESTORE_DONORS_MUTATION,
} from '../../donors/queries';
import EmployeeList from '../../employees/components/EmployeeList';
import {
  EMPLOYEE_LIST_QUERY,
  EMPTY_EMPLOYEE_TRASH_MUTATION,
  FORCE_DELETE_EMPLOYEES_MUTATION,
  RESTORE_EMPLOYEES_MUTATION,
} from '../../employees/queries';
import EventList from '../../events/components/EventList';
import { EVENT_LIST_QUERY, FORCE_DELETE_EVENTS_MUTATION, RESTORE_EVENTS_MUTATION } from '../../events/queries';
import LocationList from '../../locations/components/LocationList';
import {
  EMPTY_LOCATION_TRASH_MUTATION,
  FORCE_DELETE_LOCATIONS_MUTATION,
  LOCATION_LIST_QUERY,
  RESTORE_LOCATIONS_MUTATION,
} from '../../locations/queries';
import OrganisationList from '../../organisations/components/OrganisationList';
import {
  EMPTY_ORGANISATION_TRASH_MUTATION,
  FORCE_DELETE_ORGANISATIONS_MUTATION,
  ORGANISATION_LIST_QUERY,
  RESTORE_ORGANISATIONS_MUTATION,
} from '../../organisations/queries';
import SoulList from '../../souls/components/SoulList';
import {
  EMPTY_SOUL_TRASH_MUTATION,
  FORCE_DELETE_SOULS_MUTATION,
  RESTORE_SOULS_MUTATION,
  SOUL_LIST_QUERY,
} from '../../souls/queries';
import EventListSorter from '../../events/components/EventListSorter';

type TrashConfig = {
  listQuery: DocumentNode;
  forceDeleteMutation: DocumentNode;
  restoreMutation: DocumentNode;
  emptyTrashMutation: DocumentNode;
  listComponent: React.FC<ListComponent>;
  listSorterComponent?: React.FC;
  labelSingular: string;
  labelPlural: string;
  preposition: string;
};

const trashConfig: { [k in ItemClass]: TrashConfig } = {
  [ItemClass.Categories]: {
    listQuery: CATEGORY_LIST_QUERY,
    forceDeleteMutation: FORCE_DELETE_CATEGORIES_MUTATION,
    restoreMutation: RESTORE_CATEGORIES_MUTATION,
    emptyTrashMutation: EMPTY_CATEGORY_TRASH_MUTATION,
    listComponent: CategoryList,
    labelSingular: 'Kategorie',
    labelPlural: 'Kategorien',
    preposition: 'die',
  },
  [ItemClass.Companions]: {
    listQuery: COMPANION_LIST_QUERY,
    forceDeleteMutation: FORCE_DELETE_COMPANIONS_MUTATION,
    restoreMutation: RESTORE_COMPANIONS_MUTATION,
    emptyTrashMutation: EMPTY_COMPANION_TRASH_MUTATION,
    listComponent: CompanionList,
    labelSingular: 'Begleiter',
    labelPlural: 'Begleiter',
    preposition: 'der',
  },
  [ItemClass.Contacts]: {
    listQuery: CONTACT_LIST_QUERY,
    forceDeleteMutation: FORCE_DELETE_CONTACTS_MUTATION,
    restoreMutation: RESTORE_CONTACTS_MUTATION,
    emptyTrashMutation: EMPTY_CONTACT_TRASH_MUTATION,
    listComponent: ContactList,
    labelSingular: 'Ansprechpartner',
    labelPlural: 'Ansprechpartner',
    preposition: 'der',
  },
  [ItemClass.Locations]: {
    listQuery: LOCATION_LIST_QUERY,
    forceDeleteMutation: FORCE_DELETE_LOCATIONS_MUTATION,
    restoreMutation: RESTORE_LOCATIONS_MUTATION,
    emptyTrashMutation: EMPTY_LOCATION_TRASH_MUTATION,
    listComponent: LocationList,
    labelSingular: 'Veranstaltungsort',
    labelPlural: 'Veranstaltungsorte',
    preposition: 'die',
  },
  [ItemClass.Donors]: {
    listQuery: DONOR_LIST_QUERY,
    forceDeleteMutation: FORCE_DELETE_DONORS_MUTATION,
    restoreMutation: RESTORE_DONORS_MUTATION,
    emptyTrashMutation: EMPTY_DONOR_TRASH_MUTATION,
    listComponent: DonorList,
    labelSingular: 'Spender',
    labelPlural: 'Spender',
    preposition: 'der',
  },
  [ItemClass.Employees]: {
    listQuery: EMPLOYEE_LIST_QUERY,
    forceDeleteMutation: FORCE_DELETE_EMPLOYEES_MUTATION,
    restoreMutation: RESTORE_EMPLOYEES_MUTATION,
    emptyTrashMutation: EMPTY_EMPLOYEE_TRASH_MUTATION,
    listComponent: EmployeeList,
    labelSingular: 'Mitarbeiter',
    labelPlural: 'Mitarbeiter',
    preposition: 'der',
  },
  [ItemClass.Events]: {
    listQuery: EVENT_LIST_QUERY,
    forceDeleteMutation: FORCE_DELETE_EVENTS_MUTATION,
    restoreMutation: RESTORE_EVENTS_MUTATION,
    emptyTrashMutation: EMPTY_EMPLOYEE_TRASH_MUTATION,
    listComponent: EventList,
    listSorterComponent: EventListSorter,
    labelSingular: 'Veranstaltung',
    labelPlural: 'Veranstaltungen',
    preposition: 'die',
  },
  [ItemClass.Organisations]: {
    listQuery: ORGANISATION_LIST_QUERY,
    forceDeleteMutation: FORCE_DELETE_ORGANISATIONS_MUTATION,
    restoreMutation: RESTORE_ORGANISATIONS_MUTATION,
    emptyTrashMutation: EMPTY_ORGANISATION_TRASH_MUTATION,
    listComponent: OrganisationList,
    labelSingular: 'Organisation',
    labelPlural: 'Organisationen',
    preposition: 'die',
  },
  [ItemClass.Souls]: {
    listQuery: SOUL_LIST_QUERY,
    forceDeleteMutation: FORCE_DELETE_SOULS_MUTATION,
    restoreMutation: RESTORE_SOULS_MUTATION,
    emptyTrashMutation: EMPTY_SOUL_TRASH_MUTATION,
    listComponent: SoulList,
    labelSingular: 'Genießer',
    labelPlural: 'Genießer',
    preposition: 'der',
  },
};

const itemClassOptions = Object.entries(trashConfig).map(([itemClass, classConfig]) => ({
  value: itemClass,
  label: classConfig.labelPlural,
}));

const TrashPage = () => {
  const [itemClass, setItemClass] = useState<ItemClass>(ItemClass.Souls);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isForceDeleteAlertOpen, setIsForceDeleteAlertOpen] = useState(false);
  const [isEmptyTrashAlertOpen, setIsEmptyTrashAlertOpen] = useState(false);

  const onToggleItem = (itemId: string) => {
    const newSelectedItems = selectedItems.includes(itemId)
      ? selectedItems.filter((selectedEventId) => selectedEventId !== itemId)
      : [...selectedItems, itemId];

    setSelectedItems(newSelectedItems);
  };

  const config = trashConfig[itemClass];
  const ItemList = config.listComponent;

  const options = {
    variables: {
      ids: selectedItems,
    },
    refetchQueries: [
      {
        query: config.listQuery,
        variables: {
          trashed: Trashed.Only,
          infinite: true,
        },
      },
    ],
    awaitRefetchQueries: true,
    onError: showErrorMessage,
  };

  const changeItemClass = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedItems([]);
    setItemClass(event.currentTarget.value as ItemClass);
  };

  const [forceDeleteItems, { loading: forceDeleting }] = useMutation(config.forceDeleteMutation, {
    ...options,
    onCompleted: () => {
      AppToaster.success('Löschung erfolgreich');
      setSelectedItems([]);
    },
  });

  const [restoreItems, { loading: restoring }] = useMutation(config.restoreMutation, {
    ...options,
    onCompleted: (data) => {
      AppToaster.success('Wiederherstellung erfolgreich');
      setSelectedItems([]);
    },
  });

  const [emptyTrash, { loading: emptyingTrash }] = useMutation(config.emptyTrashMutation, {
    ...options,
    onCompleted: () => {
      AppToaster.success(`${config.labelPlural}-Papierkorb geleert`);
      setSelectedItems([]);
    },
  });

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <Alert
        isOpen={isEmptyTrashAlertOpen}
        cancelButtonText="Abbrechen"
        confirmButtonText={`${config.labelPlural}-Papierkorb leeren`}
        icon={IconNames.DELETE}
        intent={Intent.DANGER}
        onClose={() => setIsEmptyTrashAlertOpen(false)}
        onConfirm={emptyTrash as any}
      >
        <p>Soll der {config.labelPlural}-Papierkorb wirklich undwiderruflich geleert werden?</p>
      </Alert>
      <Alert
        isOpen={isForceDeleteAlertOpen}
        cancelButtonText="Abbrechen"
        confirmButtonText="Entfernen"
        icon={IconNames.DELETE}
        intent={Intent.DANGER}
        onClose={() => setIsForceDeleteAlertOpen(false)}
        onConfirm={forceDeleteItems as any}
      >
        <p>
          {selectedItems.length === 1
            ? `Soll ${config.preposition} ausgewählte ${config.labelSingular}`
            : `Sollen die ausgewählten ${config.labelPlural}`}{' '}
          wirklich undwiderruflich entfernt werden?
        </p>
      </Alert>
      <ContentCardHeader
        leftElement={
          <>
            <Text large className="mr-2">
              Papierkorb
            </Text>
            <HTMLSelect options={itemClassOptions} value={itemClass} onChange={changeItemClass} />
            {config.listSorterComponent && <config.listSorterComponent />}
          </>
        }
        rightElement={
          selectedItems.length ? (
            <>
              <Text muted>Ausgewählte {config.labelPlural}: </Text>
              <Button
                text="Wiederherstellen"
                icon={IconNames.OFFLINE}
                className="ml-2"
                onClick={restoreItems as any}
                loading={restoring}
              />
              <Button
                text="Entfernen"
                icon={IconNames.DELETE}
                intent={Intent.DANGER}
                className="ml-2"
                onClick={() => setIsForceDeleteAlertOpen(true)}
                loading={forceDeleting}
              />
            </>
          ) : (
            <Button
              text={`${config.labelPlural}-Papierkorb leeren`}
              icon={IconNames.DELETE}
              intent={Intent.DANGER}
              className="ml-2"
              onClick={() => setIsEmptyTrashAlertOpen(true)}
              loading={emptyingTrash}
            />
          )
        }
      />
      <ItemList trash onToggle={onToggleItem} isSelected={(id: string) => selectedItems.includes(id)} />
    </ContentCard>
  );
};

export default TrashPage;
