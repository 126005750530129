/** @jsxImportSource @emotion/react */
import { FetchResult, gql } from '@apollo/client';
import { Button, Colors, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css } from '@emotion/react';
import { EditorState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment, useState } from 'react';
import { Highlight } from 'react-instantsearch-dom';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FileUploadInput from '../../../components/FileUploadInput';
import FormGroup from '../../../components/FormGroup';
import MultiSelectSearchInput from '../../../components/MultiSelectSearchInput';
import Text from '../../../components/Text';
import TextInput from '../../../components/TextInput';
import ToggleInput from '../../../components/ToggleInput';
import RichTextInput, {
  decorator,
  editorStateToHTML,
  editorStateToMarkdown,
  editorStateToPlain,
  htmlToEntity,
} from '../../../components/RichTextInput';
import {
  CreateLocationInput,
  CreateLocationMutation,
  LocationCreateFormFragment,
  LocationEditFormFragment,
  UpdateLocationInput,
  UpdateLocationMutation,
  useCreateLocationMutation,
  useSetLocationPosterMutation,
  useUpdateLocationMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';
import { DonorHit } from '../../../types';

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
  street: Yup.string().required('Erforderlich'),
  postal_code: Yup.string().required('Erforderlich'),
  city: Yup.string().required('Erforderlich'),
  meeting_point: Yup.string().required('Erforderlich'),
  public_transport: Yup.string().required('Erforderlich'),
  accessible: Yup.boolean().required('Erforderlich'),
});

type LocationCreateFormValues = Omit<
  LocationCreateFormFragment,
  'accessibility_info_visual_html' | 'accessibility_info_audio_html' | 'accessibility_info_physical_html'
> & {
  accessibility_info_visual_html: EditorState;
  accessibility_info_audio_html: EditorState;
  accessibility_info_physical_html: EditorState;
};

type LocationEditFormValues = Omit<
  LocationEditFormFragment,
  'accessibility_info_visual_html' | 'accessibility_info_audio_html' | 'accessibility_info_physical_html'
> & {
  accessibility_info_visual_html: EditorState;
  accessibility_info_audio_html: EditorState;
  accessibility_info_physical_html: EditorState;
};

type LocationFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateLocationMutation) => void;
  onUpdated?: (data: UpdateLocationMutation) => void;
  initialValues: LocationCreateFormFragment | LocationEditFormFragment;
};

const isInitialEditValues = (
  initialValues: LocationCreateFormFragment | LocationEditFormFragment,
): initialValues is LocationEditFormFragment => 'id' in initialValues;

const isEditData = (
  initialValues: LocationCreateFormValues | LocationEditFormValues,
): initialValues is LocationEditFormValues => 'id' in initialValues;

const isUpdateResult = (data: CreateLocationMutation | UpdateLocationMutation): data is UpdateLocationMutation =>
  'updateLocation' in data;

const buildUpdateInput = (
  initialValues: LocationEditFormFragment,
  values: LocationEditFormValues,
): UpdateLocationInput => {
  const { donors, poster, ...otherValues }: LocationEditFormFragment = buildDiff(initialValues, values);

  return {
    ...otherValues,
    accessibility_info_visual_html: editorStateToHTML(values.accessibility_info_visual_html),
    accessibility_info_visual_markdown: editorStateToMarkdown(values.accessibility_info_visual_html),
    accessibility_info_visual: editorStateToPlain(values.accessibility_info_visual_html),
    accessibility_info_audio_html: editorStateToHTML(values.accessibility_info_audio_html),
    accessibility_info_audio_markdown: editorStateToMarkdown(values.accessibility_info_audio_html),
    accessibility_info_audio: editorStateToPlain(values.accessibility_info_audio_html),
    accessibility_info_physical_html: editorStateToHTML(values.accessibility_info_physical_html),
    accessibility_info_physical_markdown: editorStateToMarkdown(values.accessibility_info_physical_html),
    accessibility_info_physical: editorStateToPlain(values.accessibility_info_physical_html),
    ...(!!donors && {
      donors: {
        sync: donors.map((donor) => donor.id),
      },
    }),
  };
};

const buildCreateInput = (values: LocationCreateFormValues): CreateLocationInput => {
  const { poster, ...otherValues } = values;

  return {
    ...otherValues,
    accessibility_info_visual_html: editorStateToHTML(values.accessibility_info_visual_html),
    accessibility_info_visual_markdown: editorStateToMarkdown(values.accessibility_info_visual_html),
    accessibility_info_visual: editorStateToPlain(values.accessibility_info_visual_html),
    accessibility_info_audio_html: editorStateToHTML(values.accessibility_info_audio_html),
    accessibility_info_audio_markdown: editorStateToMarkdown(values.accessibility_info_audio_html),
    accessibility_info_audio: editorStateToPlain(values.accessibility_info_audio_html),
    accessibility_info_physical_html: editorStateToHTML(values.accessibility_info_physical_html),
    accessibility_info_physical_markdown: editorStateToMarkdown(values.accessibility_info_physical_html),
    accessibility_info_physical: editorStateToPlain(values.accessibility_info_physical_html),
    donors: {
      sync: values.donors.map((donor) => donor.id),
    },
  };
};

const LocationForm = ({ onCancel, onCreated, onUpdated, initialValues }: LocationFormProps) => {
  const isEdit = isInitialEditValues(initialValues);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [setLocationPoster, { loading: settingPoster }] = useSetLocationPosterMutation();
  const [updateLocation] = useUpdateLocationMutation({ refetchQueries: ['LocationDonors'] });
  const [createLocation] = useCreateLocationMutation();
  const handleSubmit = useSubmit<
    LocationCreateFormValues | LocationEditFormValues,
    FetchResult<CreateLocationMutation> | FetchResult<UpdateLocationMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateLocation({
            variables: {
              input: buildUpdateInput(initialValues as LocationEditFormFragment, values),
            },
          })
        : createLocation({
            variables: {
              input: buildCreateInput(values),
            },
          }),
    onCompleted: async (res, values) => {
      if (!res.data) return;

      // execute if updating (changing or removing poster) or creating which a poster chosen
      if (
        isUpdateResult(res.data)
          ? values.poster
            ? values.poster.url !== initialValues.poster?.url
            : true
          : values.poster
      ) {
        const locationId = isUpdateResult(res.data) ? res.data.updateLocation?.id : res.data.createLocation.id;
        await setLocationPoster({
          variables: {
            input: {
              id: locationId || '',
              image: values.poster
                ? {
                    create: {
                      file_name: values.poster.name,
                      file_path: values.poster.url,
                    },
                  }
                : {
                    delete: true,
                  },
            },
          },
        });
      }

      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik
      initialValues={{
        ...initialValues,
        accessibility_info_visual_html: EditorState.createWithContent(
          convertFromHTML({ htmlToEntity })(initialValues.accessibility_info_visual_html ?? ''),
          decorator,
        ),
        accessibility_info_audio_html: EditorState.createWithContent(
          convertFromHTML({ htmlToEntity })(initialValues.accessibility_info_audio_html ?? ''),
          decorator,
        ),
        accessibility_info_physical_html: EditorState.createWithContent(
          convertFromHTML({ htmlToEntity })(initialValues.accessibility_info_physical_html ?? ''),
          decorator,
        ),
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<Text large>Veranstaltungsort {isEdit ? 'bearbeiten' : 'hinzufügen'}</Text>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
              <TextInput name="name" placeholder="Name" />
            </FormGroup>
            <FormGroup label="Bild" name="poster">
              <FileUploadInput
                name="poster"
                onUpload={() => setUploadingMedia(true)}
                onSuccess={() => setUploadingMedia(false)}
                onError={() => setUploadingMedia(false)}
              />
            </FormGroup>
            <FormGroup label="Adresse" name="street" labelInfo="(erforderlich)">
              <TextInput name="street" type="text" placeholder="Straße" />
            </FormGroup>
            <FormGroup name="postal_code">
              <TextInput name="postal_code" type="text" placeholder="PLZ" />
            </FormGroup>
            <FormGroup name="city">
              <TextInput name="city" type="text" placeholder="Ort" />
            </FormGroup>

            <Divider className="my-6 -mx-5" />

            <FormGroup name="accessible">
              <ToggleInput name="accessible" label="Barrierefrei" />
            </FormGroup>
            <FormGroup
              label="Informationen für Menschen mit Rollstuhl/Rollator"
              name="accessibility_info_physical_html"
            >
              <RichTextInput name="accessibility_info_physical_html" />
            </FormGroup>
            <FormGroup label="Informationen für Menschen mit Sehbehinderung" name="accessibility_info_visual_html">
              <RichTextInput name="accessibility_info_visual_html" />
            </FormGroup>
            <FormGroup label="Informationen für Menschen mit Hörbehinderung" name="accessibility_info_audio_html">
              <RichTextInput name="accessibility_info_audio_html" />
            </FormGroup>
            <FormGroup label="Treffpunkt" labelInfo="(erforderlich)" name="meeting_point">
              <TextInput name="meeting_point" placeholder="Treffpunkt" />
            </FormGroup>
            <FormGroup label="Öff. Nahverkehr" labelInfo="(erforderlich)" name="public_transport">
              <TextInput name="public_transport" placeholder="Öff. Nahverkehr" />
            </FormGroup>
            <Divider className="my-6 -mx-5" />
            <FormGroup label="Spender" name="donors" labelInfo="(erforderlich)">
              <MultiSelectSearchInput<DonorHit>
                name="donors"
                index="donors"
                labelPath="name"
                // @ts-expect-error Test
                renderItem={(hit) => <Highlight hit={hit} attribute="name" css={styles.highlight} />}
              />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} className="ml-2" />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting || settingPoster || uploadingMedia}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  className="ml-2"
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

LocationForm.fragments = {
  // This is only used for Formik initialValues
  create: gql`
    fragment LocationCreateForm on Location {
      name
      poster {
        name
        url
      }
      street
      city
      postal_code
      meeting_point
      public_transport
      accessible
      accessibility_info_visual
      accessibility_info_visual_html
      accessibility_info_visual_markdown
      accessibility_info_audio
      accessibility_info_audio_html
      accessibility_info_audio_markdown
      accessibility_info_physical
      accessibility_info_physical_html
      accessibility_info_physical_markdown
      donors {
        id
      }
    }
  `,
  edit: gql`
    fragment LocationEditForm on Location {
      id
      name
      poster {
        name
        url
      }
      street
      city
      postal_code
      meeting_point
      public_transport
      accessible
      accessibility_info_visual
      accessibility_info_visual_html
      accessibility_info_visual_markdown
      accessibility_info_audio
      accessibility_info_audio_html
      accessibility_info_audio_markdown
      accessibility_info_physical
      accessibility_info_physical_html
      accessibility_info_physical_markdown
      donors {
        id
        name
      }
    }
  `,
};

export default LocationForm;

const styles = {
  highlight: css`
    em {
      font-style: normal;
      color: ${Colors.BLUE1};
    }
  `,
};
