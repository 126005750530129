/** @jsxImportSource @emotion/react */
import { FetchResult, gql } from '@apollo/client';
import { Button, Colors, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css } from '@emotion/react';
import { parseISO } from 'date-fns';
import { convertFromHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import { Highlight } from 'react-instantsearch-dom';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import DatePickerInput from '../../../components/DatePickerInput';
import DropdownSearchInput from '../../../components/DropdownSearchInput';
import FormGroup from '../../../components/FormGroup';
import NumberInput from '../../../components/NumberInput';
import RichTextInput, {
  decorator,
  editorStateToHTML,
  editorStateToMarkdown,
  editorStateToPlain,
  htmlToEntity,
} from '../../../components/RichTextInput';
import SelectInput from '../../../components/SelectInput';
import Text from '../../../components/Text';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import {
  CreateEventInput,
  CreateEventMutation,
  EventCreateFormFragment,
  EventEditFormFragment,
  EventStatus,
  EventTicketRetrievalType,
  EventTicketType,
  UpdateEventInput,
  UpdateEventMutation,
  useCreateEventMutation,
  useUpdateEventMutation,
} from '../../../generated/graphql';
import { EVENT_STATUS_OPTIONS, TICKET_RETRIEVAL_TYPE_OPTIONS, TICKET_TYPE_OPTIONS } from '../../../helpers/constants';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';
import { DonorHit } from '../../../types';
import AllotmentSelect from './AllotmentSelect';
import CategoryMultiSelect from './CategoryMultiSelect';
import ContactSelect from './ContactSelect';
import LocationSelect from './LocationSelect';

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
  start: Yup.date().typeError('Muss gültiges Datum sein').required('Erforderlich'),
  end: Yup.date().typeError('Muss gültiges Datum sein').nullable(),
  tickets: Yup.number()
    .typeError('Erforderlich')
    .integer('Muss Ganzzahl sein')
    .positive('Muss positiv sein')
    .when('ticket_type', {
      is: EventTicketType.Fixed,
      then: (schema) => schema,
      otherwise: (schema) => schema.nullable(),
    }),
  allotment: Yup.object()
    .typeError('Erforderlich')
    .when('ticket_type', {
      is: EventTicketType.Allotment,
      then: (schema) => schema,
      otherwise: (schema) => schema.nullable(),
    }),
  ticket_retrieval_type: Yup.mixed<EventTicketRetrievalType>().when('ticket_type', {
    is: EventTicketType.Unlimited,
    then: (schema) => schema.oneOf([undefined, EventTicketRetrievalType.Onsite, EventTicketRetrievalType.Delivery]),
    otherwise: (schema) => schema.oneOf([EventTicketRetrievalType.Onsite, EventTicketRetrievalType.Delivery]),
  }),
  ticket_retrieval_location: Yup.string().when(['ticket_type', 'ticket_retrieval_type'], {
    is: (ticketType: EventTicketType, ticketRetrievalType: EventTicketRetrievalType) =>
      ticketType !== EventTicketType.Unlimited && ticketRetrievalType === EventTicketRetrievalType.Onsite,
    then: (schema) => schema.required('Erforderlich'),
    otherwise: (schema) => schema,
  }),
  ticket_time: Yup.number()
    .typeError('Erforderlich')
    .integer('Muss Ganzzahl sein')
    .positive('Muss positiv sein')
    .when(['ticket_type', 'ticket_retrieval_type'], {
      is: (ticketType: EventTicketType, ticketRetrievalType: EventTicketRetrievalType) =>
        ticketType !== EventTicketType.Unlimited && ticketRetrievalType === EventTicketRetrievalType.Onsite,
      then: (schema) => schema,
      otherwise: (schema) => schema.nullable(),
    }),
  donor: Yup.object().typeError('Erforderlich'),
  contact: Yup.object().typeError('Erforderlich'),
  location: Yup.object().typeError('Erforderlich'),
});

type EventCreateFormValues = Omit<EventCreateFormFragment, 'description_html'> & {
  description_html: EditorState;
};

type EventEditFormValues = Omit<EventEditFormFragment, 'description_html'> & {
  description_html: EditorState;
};

type EventFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateEventMutation) => void;
  onUpdated?: (data: UpdateEventMutation) => void;
  initialValues: EventCreateFormFragment | EventEditFormFragment;
};

const isInitialEditValues = (
  initialValues: EventCreateFormFragment | EventEditFormFragment,
): initialValues is EventEditFormFragment => 'id' in initialValues;

const isEditData = (initialValues: EventCreateFormValues | EventEditFormValues): initialValues is EventEditFormValues =>
  'id' in initialValues;

const isUpdateResult = (data: CreateEventMutation | UpdateEventMutation): data is UpdateEventMutation =>
  'updateEvent' in data;

const buildUpdateInput = (initialValues: EventEditFormFragment, values: EventEditFormValues): UpdateEventInput => {
  const { categories, donor, contact, location, allotment, ...otherValues }: EventEditFormValues = buildDiff(
    initialValues,
    values,
  );

  return {
    ...otherValues,
    description_html: editorStateToHTML(values.description_html),
    description_markdown: editorStateToMarkdown(values.description_html),
    description: editorStateToPlain(values.description_html),
    ...(!!categories && {
      categories: {
        sync: values.categories.map((category) => category.id),
      },
    }),
    ...(!!donor && {
      donor: {
        connect: values.donor?.id,
      },
    }),
    ...(!!contact && {
      contact: {
        connect: values.contact?.id,
      },
    }),
    ...(!!location && {
      location: {
        connect: values.location.id,
      },
    }),
    ...(typeof allotment !== 'undefined' && {
      allotment: {
        connect: allotment?.id,
        disconnect: !allotment,
      },
    }),
  };
};

const buildCreateInput = (values: EventCreateFormValues): CreateEventInput => ({
  ...values,
  description_html: editorStateToHTML(values.description_html),
  description_markdown: editorStateToMarkdown(values.description_html),
  description: editorStateToPlain(values.description_html),
  categories: {
    sync: values.categories.map((category) => category.id),
  },
  donor: {
    connect: values.donor?.id,
  },
  contact: {
    connect: values.contact?.id,
  },
  location: {
    connect: values.location.id,
  },
  allotment: {
    connect: values.allotment ? values.allotment.id : null,
  },
});

const EventForm = ({ onCancel, onUpdated, onCreated, initialValues }: EventFormProps) => {
  const isEdit = isInitialEditValues(initialValues);
  const [updateEvent] = useUpdateEventMutation();
  const [createEvent] = useCreateEventMutation();
  const handleSubmit = useSubmit<
    EventCreateFormValues | EventEditFormValues,
    FetchResult<CreateEventMutation> | FetchResult<UpdateEventMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateEvent({
            variables: {
              input: buildUpdateInput(initialValues as EventEditFormFragment, values),
            },
          })
        : createEvent({
            variables: {
              input: buildCreateInput(values),
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik
      initialValues={{
        ...initialValues,
        description_html: EditorState.createWithContent(
          convertFromHTML({ htmlToEntity })(initialValues.description_html ?? ''),
          decorator,
        ),
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, values, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<Text large>Veranstaltung {isEdit ? 'bearbeiten' : 'hinzufügen'}</Text>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
              <TextInput name="name" placeholder="Name" />
            </FormGroup>
            {(values.status === EventStatus.Draft || values.status === EventStatus.Ready) && (
              <FormGroup
                label="Status"
                name="status"
                labelInfo="(erforderlich)"
                helperText="Status 'Entwurf' bedeutet, dass noch Daten oder Freigaben fehlen. Eine Veröffentlichung dieser Veranstaltungen wird blockiert."
              >
                <SelectInput name="status" options={EVENT_STATUS_OPTIONS} />
              </FormGroup>
            )}
            <FormGroup label="Start" labelInfo="(erforderlich)" name="start">
              <DatePickerInput
                name="start"
                closeOnSelection={false}
                timePrecision="minute"
                showTimezoneSelect={false}
                timePickerProps={{ showArrowButtons: true }}
              />
            </FormGroup>
            <FormGroup label="Ende" name="end">
              <DatePickerInput
                name="end"
                minDate={parseISO(values.start)}
                closeOnSelection={false}
                timePrecision="minute"
                showTimezoneSelect={false}
                timePickerProps={{ showArrowButtons: true }}
              />
            </FormGroup>
            <FormGroup label="Kategorie(n)" labelInfo="(erforderlich)" name="categories">
              <CategoryMultiSelect name="categories" />
            </FormGroup>
            <FormGroup label="Beschreibung" name="description_html">
              <RichTextInput name="description_html" />
              {/* <TextAreaInput name="description" placeholder="Beschreibung" fill rows={10} /> */}
            </FormGroup>
            <FormGroup label="Interne Notizen" name="notes">
              <TextAreaInput name="notes" placeholder="Interne Notizen" fill rows={10} />
            </FormGroup>

            <Divider className="my-6 -mx-5" />
            <FormGroup label="Karten" name="ticket_type" labelInfo="(erforderlich)">
              <SelectInput
                name="ticket_type"
                options={TICKET_TYPE_OPTIONS}
                resets={{ tickets: null, allotment: null }}
              />
            </FormGroup>
            {values.ticket_type === EventTicketType.Fixed && (
              <FormGroup label="Anzahl" labelInfo="(erforderlich)" name="tickets">
                <NumberInput name="tickets" placeholder="Karten" min={1} />
              </FormGroup>
            )}
            {values.ticket_type === EventTicketType.Allotment && (
              <FormGroup label="Kontingent" labelInfo="(erforderlich)" name="allotment">
                <AllotmentSelect name="allotment" />
              </FormGroup>
            )}
            {values.ticket_type !== EventTicketType.Unlimited && (
              <FormGroup label="Woher?" name="ticket_retrieval_type" labelInfo="(erforderlich)">
                <SelectInput name="ticket_retrieval_type" options={TICKET_RETRIEVAL_TYPE_OPTIONS} />
              </FormGroup>
            )}
            {values.ticket_type !== EventTicketType.Unlimited &&
              values.ticket_retrieval_type === EventTicketRetrievalType.Onsite && (
                <Fragment>
                  <FormGroup label="Reservierung" name="reservation_number">
                    <TextInput name="reservation_number" placeholder="Reservierungsnummer" />
                  </FormGroup>
                  <FormGroup label="Karten abholen" name="ticket_retrieval_location">
                    <TextInput name="ticket_retrieval_location" />
                  </FormGroup>
                  <FormGroup
                    label="Vorlaufzeit"
                    labelInfo="(erforderlich)"
                    helperText="Minuten vor Beginn der Veranstaltung müssen die Karten vom Kartenverteiler abgeholt werden"
                    name="ticket_time"
                  >
                    <NumberInput name="ticket_time" min={1} />
                  </FormGroup>
                </Fragment>
              )}

            <Divider className="my-6 -mx-5" />
            <FormGroup label="Spender" name="donor" labelInfo="(erforderlich)">
              <DropdownSearchInput<DonorHit>
                name="donor"
                index="donors"
                labelPath="name"
                resets={{ contact: null, location: null }}
                canClear={false}
                // @ts-expect-error Test
                renderItem={(hit) => <Highlight hit={hit} attribute="name" css={styles.highlight} />}
              />
            </FormGroup>
            {!!values.donor && (
              <FormGroup label="Ansprechpartner" name="contact" labelInfo="(erforderlich)">
                <ContactSelect name="contact" donorId={values.donor.id} />
              </FormGroup>
            )}
            {!!values.donor && (
              <FormGroup label="Veranstaltungsort" name="location" labelInfo="(erforderlich)">
                <LocationSelect name="location" donorId={values.donor.id} />
              </FormGroup>
            )}
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} className="ml-2" />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  disabled={!dirty}
                  loading={isSubmitting}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  className="ml-2"
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

EventForm.fragments = {
  create: gql`
    fragment EventCreateForm on Event {
      name
      status
      description
      description_markdown
      description_html
      start
      end
      tickets
      ticket_time
      ticket_type
      reservation_number
      ticket_retrieval_type
      ticket_retrieval_location
      notes
      categories {
        id
      }
      donor {
        id
      }
      contact {
        id
      }
      location {
        id
      }
      allotment {
        id
      }
    }
  `,
  edit: gql`
    fragment EventEditForm on Event {
      id
      name
      status
      description
      description_markdown
      description_html
      start
      end
      tickets
      ticket_time
      ticket_type
      reservation_number
      ticket_retrieval_type
      ticket_retrieval_location
      notes
      categories {
        id
        name
      }
      donor {
        id
        name
      }
      contact {
        id
        display_name
      }
      location {
        id
        name
      }
      allotment {
        id
        name
      }
    }
  `,
};

export default EventForm;

const styles = {
  highlight: css`
    em {
      font-style: normal;
      color: ${Colors.BLUE1};
    }
  `,
};
